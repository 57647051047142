<script setup lang="ts">
import type { ComponentPublicInstance } from 'vue'
import VueDraggable from 'vuedraggable'
import type { TransferListOptionsItem } from '@reports/components/TransferList/types'
import { useScrollToBottom } from '@shared/composables/useScrollToBottom'

interface Props {
  options: TransferListOptionsItem[]
  selected?: TransferListOptionsItem[]
  draggable?: boolean
  scrollToBottom?: boolean
  variant?: 'primary' | 'secondary' | 'tertiary'
}

const props = withDefaults(defineProps<Props>(), {
  options: () => [],
  selected: () => [],
  draggable: false,
  scrollToBottom: false,
  variant: 'primary',
})

const emit = defineEmits(['update:options'])
const options = useVModel(props, 'options', emit)
const isDragging = ref(false)

/* === METHODS === */
const isSelected = (option: TransferListOptionsItem) =>
  props.selected.some((selected) => selected.value === option.value)
/* === METHODS === */

/* === SCROLL TO BOTTOM === */
const listRef = ref<ComponentPublicInstance | HTMLElement>()
// Scroll to the bottom of the ul element
const _scrollToBottom = useScrollToBottom(
  computed(() => unrefElement(listRef) as HTMLElement | undefined),
  { onMounted: props.scrollToBottom },
)
// Watch the options prop for changes
watch(
  () => props.options,
  (newOptions, oldOptions) => {
    if (!props.scrollToBottom) return

    if (newOptions.length > oldOptions.length) {
      _scrollToBottom()
    }
  },
)
/* === SCROLL TO BOTTOM === */
</script>

<template>
  <VueDraggable
    v-if="draggable"
    ref="listRef"
    v-model="options"
    tag="ul"
    item-key="value"
    :animation="300"
    class="group/list divide-y"
    :class="{
      'divide-gray-750': variant === 'primary',
    }"
    :data-dragging="isDragging"
    @start="isDragging = true"
    @end="isDragging = false"
  >
    <template #item="{ element: option }">
      <li class="group/item">
        <slot name="item" :option="option" :selected="isSelected(option)" />
      </li>
    </template>
    <template v-if="options.length === 0" #header>
      <ReportsTransferListEmpty>
        <slot name="empty" />
      </ReportsTransferListEmpty>
    </template>
  </VueDraggable>
  <ul
    v-else
    ref="listRef"
    class="group/list divide-y"
    :class="{
      'divide-gray-750': variant === 'primary',
      'divide-gray-700': variant === 'secondary',
      'divide-gray-650': variant === 'tertiary',
    }"
  >
    <template v-for="option in options" :key="option.value">
      <li class="group/item">
        <slot name="item" :option="option" :selected="isSelected(option)" />
      </li>
    </template>
    <ReportsTransferListEmpty v-if="options.length === 0">
      <slot name="empty" />
    </ReportsTransferListEmpty>
  </ul>
</template>
