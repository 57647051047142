import type { Ref } from 'vue'

export interface UseScrollToBottomOptions {
  onMounted?: boolean
}

export function useScrollToBottom(
  element: Ref<HTMLElement | null | undefined>,
  options: UseScrollToBottomOptions = {},
) {
  const { onMounted: scrollOnMounted = false } = options
  const scrollToBottom = async () => {
    if (!element.value) return
    await nextTick()
    element.value.scrollTo({
      top: element.value.scrollHeight,
      behavior: 'smooth',
    })
  }

  if (scrollOnMounted) {
    onMounted(() => {
      scrollToBottom()
    })
  }

  return scrollToBottom
}
